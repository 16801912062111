/*
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 100%;
  padding: 6px;
  border-radius: 4px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 200px;
  //height: auto;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
}

.popup-overlay {
  //background: rgba(0, 0, 0, 1);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}
*/

.comment-popup-content {
  margin: auto;
  background: white;
  width: 50%;
  padding: 5px;
}
.comment-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].comment-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
}

.comment-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].comment-popup-overlay {
  background: transparent;
}
