.board-popup-content {
  //margin: auto;
  background: white;

  width: 50%;
  //padding: 5px;
}
.board-popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].board-popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.board-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].board-popup-overlay {
  background: transparent;
}
