@import 'node_modules/bootstrap/scss/functions';
@import './_variables.scss';
@import './components.scss';
//@import './sidebar.scss';
//@import './navbar-fixed-left.scss';

html,
body {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

body {
  overflow-x: hidden;
  height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
}

#root {
  height: 100%;
}

.no-blurry {
  backface-visibility: hidden;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1; /* Firefox */
}

.p-small {
  font-size: 0.8rem;
  margin-bottom: 0;
}

.p-play {
  font-size: 0.65rem;
  letter-spacing: 0.4px;
  margin-bottom: 0;
}

.pointer {
  cursor: pointer;
  box-shadow: none;
}

.btn:focus {
  outline: none;
}

.btn-primary:focus,
.btn-info:focus {
  box-shadow: none;
}

.MyDragHandleClassName {
  color: red;
  background: green;
  width: 10px;
  height: 10px;
}

.hide {
  opacity: 0;
}

.box {
  background: #ffffff;
  box-shadow: 0px 2px 6px -2px rgba(0, 0, 0, 0.3);

  border-radius: 4px;
}

.back-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.no-pointer-events {
  pointer-events: none;
}
